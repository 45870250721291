<script>
	import store from '../../store';
	const pageName = 'onboarding';
	const nextPage = 'sector-selector';
	export default {
		beforeRouteEnter(to, from, next) {
			if(store.getters.pageViewed(pageName)) {
				return next({name: nextPage});
			}
			next();
		},
		data() {
			return {
				carouselCount: 3,
				setTimeout: null,
				offsetWidth: 0,
				scrollLeft: 0,
				selectedIndex: 0,
				pageRight: true
			}
		},
		mounted() {
			this.updateOffsetWidth();
			window.addEventListener('resize', this.updateOffsetWidth);
			this.$store.dispatch('pageViewed', pageName);
		},
		watch: {
			selectedIndex(newIndex, oldIndex) {
				this.pageRight = newIndex > oldIndex;
				this.scrollLeft = newIndex * this.$el.offsetWidth;
				this.$refs.scrollableParent.scrollTo({ left: this.scrollLeft, behavior: 'smooth' });
			},
			// can change when scrollbar appear
			'this.$root.$el.scrollHeight'() {
				this.updateOffsetWidth();
			}
		},
		destroyed() {
			window.removeEventListener('resize', this.updateOffsetWidth);
		},
		methods: {
			updateOffsetWidth() {
				this.offsetWidth = this.$el.offsetWidth;
			},
			onScroll(e) {
				clearTimeout(this.setTimeout);
				this.setTimeout = setTimeout(() => {
					const scrollingLeft = e.target.scrollLeft > this.scrollLeft,
						exactlyOnAnItem = e.target.scrollLeft % this.offsetWidth === 0,
						modifier = scrollingLeft || exactlyOnAnItem ? 0 : -1;
					this.paginateTo(Math.ceil(e.target.scrollLeft / this.offsetWidth) + modifier);
				}, 200);
			},
			paginateTo(index) {
				this.selectedIndex = index;
			},
			next() {
				const nextIndex = this.selectedIndex + 1;
				if(nextIndex < this.carouselCount) {
					return this.paginateTo(nextIndex);
				}
				this.$router.replace({name: nextPage});
			}
		}
	}
</script>
<template>
	<div id="onboarding">
		<div class="image-carousel">
			<div
					class="carousel-wrapper"
					:style="{'--carousel-outer-width': offsetWidth + 'px'}"
					@scroll="onScroll"
					ref="scrollableParent"
			>
				<div class="carousel-inner">
					<img src="../../assets/images/onboarding-1.svg"/>
					<img src="../../assets/images/onboarding-2.svg"/>
					<img src="../../assets/images/onboarding-3.svg"/>
				</div>
			</div>
		</div>
		<div class="panel">
			<div class="navigation">
				<span
						v-for="(v, index) in Array(carouselCount)"
						:key="index"
						class="dot"
						:class="{'active': selectedIndex === index}"
						@click.stop="paginateTo(index)"></span>
			</div>
			<transition :name="pageRight ? 'slide-fade-left' : 'slide-fade-right'" mode="out-in">
				<div class="texts" :key="selectedIndex">
					<h1>{{$t('onboarding-title-' + selectedIndex)}}</h1>
				</div>
			</transition>
			<div class="buttons">
				<div>
					<button class="gold-button large btn" @click="next">{{$t('button-next')}}</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	#onboarding {
		width: 100%;
		height: 100vh;
		margin-top: -60px;
		overflow: hidden;
		background: white;
		overscroll-behavior: none;
		display: flex;
		flex-direction: column;
	}
	.image-carousel {
		flex: 1;
		margin-bottom: -30px;
		height: calc(100% - 366px);
	}
	.panel {
		padding: 30px 20px 0;
		border-radius: 30px 30px 0 0;
		box-shadow: 0 -10px 40px #00000029;
		bottom: 0;
		background: white;
		z-index: 99;
	}
	.texts {
		height: 90px;
		text-align: center;
	}
	.buttons > div {
		display: flex;
	}
	.btn {
		text-transform: uppercase;
	}
	.gold-button {
		width: 100%;
		margin-bottom: 20px;
	}
	.plain-button {
		padding: 6px 0;
		margin: 0 auto 30px;
		display: inline;
	}

	.image-carousel {
		width: 100%;
		position: relative;
	}
	.carousel-wrapper {
		overflow-x: auto;
		display: flex;
		height: 100%;
	}
	.carousel-inner {
		display: inline-flex;
	}
	.carousel-inner img {
		display: inline-block;
		width: var(--carousel-outer-width);
		height: 100%;
		padding: 30px 20px;
		box-sizing: border-box;
	}

	.navigation {
		width: 100%;
		text-align: center;
		margin-bottom: 24px;
	}
	.navigation .dot {
		display: inline-block;
		width: 10px;
		height: 10px;
		background: #CECECE;
		border-radius: 50%;
		transition: all 200ms ease-in;
	}
	.navigation .dot:not(:last-of-type) {
		margin-right: 10px;
	}
	.navigation .dot.active {
		background: var(--theme-color-3);
	}
</style>
